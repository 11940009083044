import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function LanguageSelector() {
  const { i18n } = useTranslation(); // Access i18n instance
  const [language, setLanguage] = useState('en'); // Default to English
  const walletAddress = localStorage.getItem('walletAddress'); // Retrieve walletAddress from localStorage


  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';


  // Step 2: Retrieve the language value from localStorage if available
  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []); // Empty dependency array to run this effect only once, during component initialization

  // Function to handle language change
  const handleLanguageChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;

    // Update state and localStorage
    setLanguage(newLanguage);
    localStorage.setItem('pxlLanguage', newLanguage);
    i18n.changeLanguage(newLanguage);

    if (walletAddress && walletAddress !== "0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a") {
      try {
        // Construct the API URL using the wallet address and the new language value
        const apiUrl = `${linkUrl}/wp-json/custom/v1/update_user_metadata/?walletValue=${encodeURIComponent(walletAddress)}&metadataA=peaxel_language&valueA=${encodeURIComponent(newLanguage)}`;
        
        // Use axios to send the GET request to the API
        const response = await axios.get(apiUrl);
        
        if (response.data.success) {
          console.log('User metadata updated successfully');
        } else {
          console.error('Failed to update user metadata:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    }


    // Reload the page to reflect the new language
    window.location.reload();
  };

  return (
    <span>
      <select value={language} onChange={handleLanguageChange} className="currency-selector">
        <option value="ar">Arabic</option>
        <option value="cn">Chinese</option>
        <option value="nl">Dutch</option>
        <option value="en">English</option>
        <option value="fr">French</option>
        <option value="de">German</option>
        <option value="he">Hebrew</option>
        <option value="it">Italian</option>
        <option value="jp">Japanese</option>
        <option value="kr">Korean</option>
        <option value="pt">Portuguese</option>
        <option value="ru">Russian</option>
        <option value="sp">Spanish</option>
      </select>
    </span>
  );
}

export default LanguageSelector;
